<template>
    <div>
        <card-header title="New Day" icon="fa-plus"/>

        <card-body>
            <day-form :day="newDay"/>
        </card-body>

        <card-footer class="pt-3 pb-3 has-text-right">
            <b-button type="is-primary" size="is-small" :disabled="!newDay.title || !newDay.type || !newDay.day_on" @click="submit"><i class="fas fa-plus mr-3"></i>New Day</b-button>
        </card-footer>
    </div>
</template>

<script>
    import CardHeader from '../../TIER/components/CardHeader';
    import CardContainer from '../../TIER/components/CardContainer';

    import {client as http} from '../../http_client';
    import CardFooter from "../../TIER/components/CardFooter";
    import CardList from "../../TIER/components/CardList";
    import SubheaderListItem from "../../TIER/components/SubheaderListItem";

    import CardBody from "../../TIER/components/CardBody";
    import ContainerListItem from "../../TIER/components/ContainerListItem";
    import DayForm from "./DayForm";

    export default {
        props: ['card', 'props'],
        components: {DayForm, ContainerListItem, CardBody, SubheaderListItem, CardList, CardFooter, CardContainer, CardHeader},
        data: function () {
            return {
                newDay: {
                    title: '',
                    description: '',
                    type: 'trip'
                }
            };
        },
        methods: {
            submit() {
                this.$emit('loading', true);
                http.post('/api/days/', this.newDay, {force: true}).then(response => {
                    this.$reloadCard('days', {tripId: this.props.tripId});
                    this.$saveAlert(false);
                    this.$closeCard(this.card);
                }).catch(err => {

                });
            }
        },
        mounted() {
            this.newDay.trip_id = this.props.tripId;
            this.$saveAlert(true, 'Are you done creating this new day? Unsaved changes will be lost.');
        }
    };
</script>
